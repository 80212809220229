import React, { useState } from 'react';
import emailjs from '@emailjs/browser'
import TopBar from '../components/TopBar';
import Background from '../components/Background';
import "./contact.css"

const Contact = () => {
    const [inputValues, setInputValues] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    });

    const clearSubmitBackground = () => {
        let sendMessageButton = document.getElementById("send-message");

        sendMessageButton.innerHTML = "Submit";
        sendMessageButton.style.background = "rgb(15, 15, 15)";
        sendMessageButton.style.color = "rgb(245, 245, 245)";
    }

    const sendMessage = () => {
        let sendMessageButton = document.getElementById("send-message");

        emailjs.init(process.env.REACT_APP_TOKEN);
        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, inputValues)
        .then(response => {
            console.log('SUCCESS!', response.status, response.text);
            sendMessageButton.innerHTML = "Sent !";
            sendMessageButton.style.background = "rgb(125, 125, 125)";
            sendMessageButton.style.color = "rgb(15, 15, 15)";
            setTimeout(clearSubmitBackground, 5000);
            resetInputs();
        }, error => {
            console.log('FAILED...', error);
            sendMessageButton.innerHTML = "Error...";
            sendMessageButton.style.background = "rgb(125, 125, 125)";
            sendMessageButton.style.color = "rgb(15, 15, 15)";
            setTimeout(clearSubmitBackground, 5000);
            resetInputs();
        });
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues({
            ...inputValues,
            [name]: value,
        });
        console.log(inputValues);
    };

    const resetInputs = () => {
        const lastNameInputBox = document.getElementById('lastname-box');
        const firstNameInputBox = document.getElementById('firstname-box');
        const emailInputBox = document.getElementById('mail-box');
        const phoneInputBox = document.getElementById('phone-box');
        const messageInputBox = document.getElementById('message-box');


        lastNameInputBox.classList.remove("error");
        firstNameInputBox.classList.remove("error");
        emailInputBox.classList.remove("error");
        phoneInputBox.classList.remove("error");
        messageInputBox.classList.remove("error");
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    }

    const isValidPhoneNumber = (phone) => {
        const phoneRegex = /^(\d| )+$/;

        return phoneRegex.test(phone);
    }

    const checkFormValidity = () => {
        const lastNameInputBox = document.getElementById('lastname-box');
        const firstNameInputBox = document.getElementById('firstname-box');
        const emailInputBox = document.getElementById('mail-box');
        const phoneInputBox = document.getElementById('phone-box');
        const messageInputBox = document.getElementById('message-box');
        let isValid = true;

        if (!inputValues.firstname.trim()) {
            firstNameInputBox.classList.add('error');
            isValid = false;
        }

        if (!inputValues.lastname.trim()) {
            lastNameInputBox.classList.add('error');
            isValid = false;
        }

        if (!inputValues.message.trim()) {
            messageInputBox.classList.add('error');
            isValid = false;
        }

        if (!inputValues.email.trim() && !inputValues.phone.trim()) {
            emailInputBox.classList.add('error');
            phoneInputBox.classList.add('error');
            isValid = false;
        }

        if (inputValues.email.trim() && !isValidEmail(inputValues.email)) {
            emailInputBox.classList.add('error');
            isValid = false;
        }

        if (inputValues.phone.trim() && !isValidPhoneNumber(inputValues.phone)) {
            phoneInputBox.classList.add('error');
            isValid = false;
        }
        return isValid;
    };


    const submitMessage = () => {

        if (checkFormValidity()) {
            sendMessage()
            resetInputs()
        } else {
            console.error("Error sending message...");
        }
    }

    return (
        <div className='contact'>
            <Background />
            <TopBar />
            <div className="main-form">
            <div className="form-container">
                <h3>Contact us</h3>


                <div className="user-name">
                    <div className="input-box" id="lastname-box">
                        <i className="bx bx-user"></i>
                        <input type="text" name="lastname" id="lastname" placeholder="Lastname" onChange={handleInputChange} />
                    </div>
                    <div className="input-box" id="firstname-box">
                        <i className="bx bx-user"></i>
                        <input type="text" name="firstname" id="firstname" placeholder="Firstname" onChange={handleInputChange} />
                    </div>
                </div>


                <div className="input-box" id="mail-box">
                    <i className="bx bx-envelope"></i>
                    <input type="email" name="email" id="email" placeholder="Email" onChange={handleInputChange} />

                </div>


                <div className="input-box" id="phone-box">
                    <i className='bx bx-phone'></i>
                    <input type="text" name="phone" id="phone" placeholder="Phone no." onChange={handleInputChange} />

                </div>


                <div className="input-box" id="message-box">
                    <textarea name="message" id="message" rows="10" placeholder="How can we help you ?" onChange={handleInputChange} />
                </div>


                <div className="submit-btn">
                    <p id="send-message" onClick={submitMessage}>Submit</p>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Contact;

// firstnameBox.classList.add("error");
