import React from 'react';
import "./home.css"
import { Link } from "react-router-dom";
import TopBar from '../components/TopBar.js';
import facebookLogo from "../assets/social_networks_logo/facebook.png"
import instaLogo from "../assets/social_networks_logo/instagram.png"
import Background from '../components/Background';

const Home = () => {
    return (
        <div className='home'>
            <Background />
            <TopBar />
            <div className="main-container">
                <div className="mid-container">
                    <h1>COMING SOON</h1>
                    <p>We work hard to provide you with an exceptional user experience</p>
                    <div className="contact-btn">
                        <Link to="/contact">Contact us</Link>
                    </div>
                </div>

                <div className="social-networks">
                    <Link to="https://www.facebook.com/syred.agency/" target='_blank'>
                        <img src={facebookLogo} alt="Facebook icon"/>
                    </Link>
                    <Link to="https://www.instagram.com/syred.agency/" target='_blank'>
                        <img src={instaLogo} alt="Instagram icon"/>
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default Home;
