import React from 'react';
import "./background.css"

const Background = () => {
    return (
        <div className="background-image"></div>
    );
};

export default Background;
