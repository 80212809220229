import React from 'react';
import { Link } from "react-router-dom";
import "./topBar.css"
import logo from "../assets/logos/1000x1000/dark.png"

const TopBar = () => {
    return (
        <div>
            <div className="logo-container">
                <Link to="/">
                    <img id="top-logo" src={logo} alt="Syred Agency logo"/>
                </Link>
            </div>
        </div>
    );
};

export default TopBar;
